<template>
    <div class="st-fadein-content">
        <table class="table table-hover table-bordered table-striped table-sm" style="margin-bottom: 0;">
            <thead>
            <tr>
                <template v-for="(field,index) in fields">
                    <template v-if="field.showintable && field.type === 'ID'">
                        <th :key="'crudheader-' + field.name + '-' + index" class="pt-1 pl-2" style="width: 30px;">
                            <input type="checkbox" :id="field.name+'-'+data.id" v-model="selectAll"
                                   v-on:change="selectAllRecords"/>
                        </th>
                    </template>
                    <template v-else>
                        <CrudTableHeader :key="field.name+'-'+field.id" :field="field" :pageInfo="pageInfo"
                                         v-on:toggle-sort="toggleSort"
                                         v-on:toggle-stateintable="$emit('toggle-stateintable', $event)"
                                         v-if="field.showintable"/>
                    </template>
                </template>
                <th style="text-align: center">
                    <font-awesome-icon :icon="['fas', 'cogs']" size="sm"/>
                    {{ $i18n.t('translations.Actions') }}
                </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(row,index) in data">
                <CrudTableRow :key="'row-'+row.id+'-'+reloadRow" :data="row" :fields="fields" :actions="actions" :state="state"
                              v-on:delete-row="$emit('delete-row',row)"
                              v-on:detail-row="$emit('detail-row',row)"
                              v-on:inline-edit="inlineEdit(index,$event)"
                              v-on:list-row="$emit('list-row',row)"
                              v-on:restore-row="$emit('restore-row',row)"
                              v-on:select-row="checkIfRowSelected"
                              v-on:update-row="$emit('update-row',row)"/>
            </template>
            </tbody>
        </table>

        <div class="container-fluid mt-3 p-0">
            <div class="row">
                <div class="col">
                    <template v-for="(action,index) in actions">
                        <DropDownAction :key="'crudfooter-' + action.name + '-' + index"
                                        :action="action" :disabled="actionDisabled(action)" forward-events
                                        v-on:forward-event="handleForwardedEvent(action, $event)"
                                        v-if="action.type === 'DROPDOWN' && action.placement === 'TABLEFOOTERLEFT'"/>
                    </template>
                </div>
                <div class="col-auto">
                    <template v-for="(action,index) in actions">
                        <DropDownAction :key="'crudfooter-' + action.name + '-' + index"
                                        :action="action" :disabled="actionDisabled(action)" forward-events
                                        v-on:forward-event="handleForwardedEvent(action, $event)"
                                        v-if="action.type === 'DROPDOWN' && action.placement === 'TABLEFOOTERRIGHT'"/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CrudTableHeader from "./CrudTableHeader";
import CrudTableRow from "./CrudTableRow";
import DropDownAction from "@/components/actions/DropDownAction";

export default {
    name: "CrudTable",
    components: {
        CrudTableRow,
        CrudTableHeader,
        DropDownAction,
    },
    props: ["fields", "data", "actions", "pageInfo", "state"],
    data() {
        return {
            rowSelected: false,
            selectAll: false,
            reloadRow: 0,
        }
    },
    methods: {
        checkIfRowSelected() {
            this.rowSelected = false;
            this.data.forEach(row => {
                if (row.selected) {
                    this.rowSelected = true;
                }
            });
        },
        actionDisabled(action) {
            if (action.enable === 'selection') {
                return !this.rowSelected;
            }
            return false;
        },
        handleForwardedEvent(action, eventData) {
            if (action.enable === 'selection') {
                let ids = [];
                this.data.forEach(row => {
                    if (row.selected) {
                        ids.push(row.id);
                    }
                });
                eventData.data.ids = ids;
            }
            if (this.forwardEvents) {
                this.$emit('forward-event', eventData);
            } else {
                this.$emit(eventData.event, eventData.data);
            }
        },
        inlineEdit(index, data) {
            this.data[index] = data.data;
            this.reloadRow++;
            this.state.loading = false;
            this.$emit('inline-edit', data);
        },
        toggleSort(name) {
            this.$emit('toggle-sort', name);
        },
        selectAllRecords() {
            this.data.filter(row => row.selected = this.selectAll);
            this.checkIfRowSelected();
        }
    }
}
</script>

<style scoped>
</style>
